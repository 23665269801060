import React from "react"
import { Flex, Box, FlexProps } from "@chakra-ui/core"
import animations from "../../constants/animations"

const LoadingIndicator: React.FC<
  { animate?: boolean; time?: string } & FlexProps
> = ({ width, height, time = "2s", animate, ...props }) => (
  <Flex bg="noon" w={width} height="2px" borderRadius="1px" {...props}>
    <Box
      bg="primary"
      w="100%"
      transformOrigin="left"
      transform={`scaleX(${animate ? 1 : 0})`}
      transition={`transform ${animate ? time : 0} ${animations.bezier}`}
      borderRadius="1px"
    />
  </Flex>
)

export default LoadingIndicator
