import React from "react"
import * as R from "ramda"
import { Flex, Text, Box, Icon } from "@chakra-ui/core"
import { Link as GatsbyLink, navigate, PageProps } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import BikeListLayout from "../components/Layout/BikeListLayout"
import MobileHeader from "../components/SideBar/MobileHeader"
import LoadingIndicator from "../components/BikeSelect/LoadingIndicator"
import ToolTip from "../components/ToolTip"
import Heading from "../components/typography/Heading"
import Paragraph from "../components/typography/Paragraph"
import SmallCaps from "../components/typography/SmallCaps"
import { BikeVariant } from "../components/BikeProductCard"
import { Button } from "../components/Buttons"

import { BaseDispatch, BaseRootState } from "../redux/store"
import { LaptopDownOnly, LaptopUpOnly } from "../utils/MediaQueries"
import { placeholderImage } from "../utils"
import animations from "../constants/animations"
import useShopifyVariantPricing from "../hooks/useShopifyVariantPricing"
import InStockFilter from "./BicycleProductList/InStockFilter"
import { useQueryParam, BooleanParam } from "use-query-params"
import useLayoutMeasurements from "../hooks/useLayoutMeasurements"
import { BikeType } from "../redux/types/BikeTypes"
import { QuizBikeSelectQuery } from "../../graphql-types"

import "./party-effect.css";

type QuizBikeSelectProps = PageProps<
  QuizBikeSelectQuery,
  {},
  { previousPath?: string }
>

const Link: React.FC<{ children: string; to: string }> = ({ children, to }) => (
  <GatsbyLink to={to}>
    <Text
      as="span"
      color="primary"
      borderBottom="1px solid"
      borderBottomColor="primary"
      paddingBottom="2px"
    >
      {children}
    </Text>
  </GatsbyLink>
)

const QuizBikeSelect: React.FC<QuizBikeSelectProps> = ({ data, location }) => {
  const dispatch = useDispatch<BaseDispatch>()
  const {
    bikes: { bikeResults: _bikeResults, bikeProgressState, heightRestriction },
    quiz: { quizProgressState },
  } = useSelector((state: BaseRootState) => state)
  const [animateLoader, setAnimateLoader] = React.useState<boolean>(false)
  const [showResults, setShowResults] = React.useState<boolean>(false)
  const defaultShowInStockResults =
    !!data.globalConfig?.bodyFitShowInStockResults
  const [showInStockParam, setShowInStock] = useQueryParam(
    "stock",
    BooleanParam
  )
  const showInStock = !!(showInStockParam ?? defaultShowInStockResults)
  const layout = useLayoutMeasurements()
  const isMounted = React.useRef(false)

  // Pricing
  const { pricingMap } = useShopifyVariantPricing([
    ...R.uniq(
      R.flatten(
        _bikeResults?.map((bike) =>
          bike?.speeds?.map((speed) =>
            speed?.variants?.map((variant) => variant?.shopifyId)
          )
        ) || []
      )
    ),
  ])

  React.useEffect(() => {
    if (_bikeResults === null) {
      // Score and filter bikes
      dispatch.bikes.calculateResults()
    } else {
      // Animate the "calculating"
      setAnimateLoader(true)
      setShowResults(false)
      setTimeout(() => {
        if (isMounted.current) {
          setShowResults(true)
          setAnimateLoader(false)
        }
      }, 2000)
    }
  }, [_bikeResults])

  React.useEffect(() => {
    isMounted.current = true

    if (bikeProgressState === "none") {
      dispatch.sidebar.setSideBarStep("fit")
      // navigate("/")
    }

    return () => {
      isMounted.current = false
    }
  }, [])

  const previousPath = location?.state?.previousPath
    ? location.state.previousPath
    : "/"

  const onExit = () => {
    dispatch.bikes.reset()
    dispatch.sidebar.setSideBarStep("fit")
    if (quizProgressState === "finished") {
      dispatch.quiz.onQuizExit()
    }
    navigate(previousPath)
  }

  const preOrderBikes = _bikeResults
  ? (
      _bikeResults.map((bike: BikeType) => ({
        id: bike.internalTitle!,
        title: bike.title!,
        variants: R.flatten(
          bike.speeds?.map((speed) =>
            speed?.variants?.map(
              (variant) =>
                (({
                  uniqueId: variant?.shopifyId,
                  productHandle: speed.internalTitle,
                  speed: speed.speed,
                  color: variant?.color,
                  image:
                    variant?.productListingImage?.fluid || placeholderImage,
                  pricing: pricingMap[variant?.shopifyId || ""] || null,
                } || []) as BikeVariant)
            )
          ) || []
        ).filter((variant) => {
          
          if (showInStock) {
            if (variant?.pricing && variant?.pricing?.availableForSale && variant?.pricing?.currentlyNotInStock) {
              return true;
            }
          }
          return false;
        }) as BikeVariant[],
        matchPercent: bike.score,
        onClick: ({ speed, color }) => {
          dispatch.bikes.setProgressState("selected")
          //@ts-ignore
          navigate(`/customizer/${bike.internalTitle}`, {
            state: {
              previousPath,
              fromQuizResults: {
                speed,
                color,
              },
            },
          })
        },
      })) ?? []
    ).filter((bike) => {
      const variants = bike.variants.filter(
        ({ color, speed }) => color !== null && speed !== null
      )
      return variants.length !== 0
    })
  : []

  const bikeResults = _bikeResults
    ? (
        _bikeResults.map((bike: BikeType) => ({
          id: bike.internalTitle!,
          title: bike.title!,
          variants: R.flatten(
            bike.speeds?.map((speed) =>
              speed?.variants?.map(
                (variant) =>
                  (({
                    uniqueId: variant?.shopifyId,
                    productHandle: speed.internalTitle,
                    speed: speed.speed,
                    color: variant?.color,
                    image:
                      variant?.productListingImage?.fluid || placeholderImage,
                    pricing: pricingMap[variant?.shopifyId || ""] || null,
                  } || []) as BikeVariant)
              )
            ) || []
          ).filter((variant) => {
            if (showInStock) {
              if (variant?.pricing && variant?.pricing?.availableForSale && variant?.pricing?.currentlyNotInStock) {
                return false;
              }
              return (
                !variant?.pricing?.currentlyNotInStock &&
                variant?.pricing?.availableForSale
              )
            }
            return true
          }) as BikeVariant[],
          matchPercent: bike.score,
          onClick: ({ speed, color }) => {
            dispatch.bikes.setProgressState("selected")
            //@ts-ignore
            navigate(`/customizer/${bike.internalTitle}`, {
              state: {
                previousPath,
                fromQuizResults: {
                  speed,
                  color,
                },
              },
            })
          },
        })) ?? []
      ).filter((bike) => {
        const variants = bike.variants.filter(
          ({ color, speed }) => color !== null && speed !== null
        )
        return variants.length !== 0
      })
    : []

  const finalBikeResults = [...bikeResults, ...preOrderBikes];

  return (
    <>
      <LaptopUpOnly>
        {/* Desktop Exit bar */}
        <Flex w="100%" justifyContent="flex-end" pt="3.5rem" pr="2rem">
          <Button
            theme="tertiary"
            color="dawn"
            p="1.0938rem 1.3125rem 1.0938rem 1rem"
            onClick={onExit}
          >
            <Flex alignItems="center">
              <Icon name="chevron" size="1.25rem" mr="0.875rem" />
              <SmallCaps size="lg" fontWeight="medium">
                Exit
              </SmallCaps>
            </Flex>
          </Button>
        </Flex>
      </LaptopUpOnly>
      {/* Loading Screen */}
      <Flex
        position="fixed"
        top={0}
        left={0}
        zIndex={1}
        align="center"
        justify="center"
        direction="column"
        w={layout.contentWidthResponsive}
        h="100vh"
        pb="11vh"
        bg="white"
        pointerEvents={!showResults ? "visible" : "none"}
        opacity={!showResults ? 1 : 0}
        transition={`opacity 300ms ${animations.bezier}`}
      >
        <ToolTip label="Calculating Results!" isOpen={animateLoader} zIndex={2}>
          <LoadingIndicator
            animate={animateLoader}
            h="2px"
            w={["50vw", null, null, null, "20vw"]}
            mt="2.25rem"
          />
        </ToolTip>
      </Flex>
      {/* Content */}
      <Box opacity={showResults ? 1 : 0}>
        <BikeListLayout
          pb="7.5rem"
          bikes={finalBikeResults}
          tooltipLabel={showResults ? "Your top match!" : undefined}
          featureFirstBike
          header={
            <Flex
              gridColumn="1/3"
              flexDir="column"
              alignItems="start"
              mb={["6.25rem", null, null, null, "0"]}
            >
              <LaptopDownOnly>
                <MobileHeader state="bike" onExit={onExit} />
              </LaptopDownOnly>
              {/*
              <Flex flexDir="column" alignItems="center" p="4.75rem 2.625rem">
                <Heading size="4" fontWeight="bold" color="night">
                  <LaptopUpOnly>You&apos;ve got </LaptopUpOnly>
                  {bikeResults?.length} Match
                  {(bikeResults?.length !== 1 && "es") || ""}
                  {bikeResults?.length !== 0 && "!"}
                </Heading>

                <InStockFilter
                  id="quiz-in-stock-filter"
                  enabled={showInStock}
                  setEnabled={setShowInStock}
                  mt="0.75rem"
                />
                <Paragraph lineHeight="1.5rem" marginTop="15px">{(heightRestriction && "We apologize no standard bike/ebike options match with your height and/or weight. Please email us, theteam@sixthreezero.com or call us (310) 982-2877 to find out what custom options are available. We want to find a perfect match for everyone.") || ""}</Paragraph>
              </Flex>
          */}
            </Flex>
          }
          inStockFilter = {
            <InStockFilter
                  id="quiz-in-stock-filter"
                  enabled={showInStock}
                  setEnabled={setShowInStock}
                  mt="0.75rem"
                  ml="2.935rem"
                  mb="0.75rem"
                  justify="left"
                  align="left"
                  className="cb_show_in_stock"
                />
          }

          inStockFilterCenter = {
            <InStockFilter
                  id="quiz-in-stock-filter"
                  enabled={showInStock}
                  setEnabled={setShowInStock}
                  justify="center"
                  align="center"
                  mb="2rem"
                />
          }
        />
      </Box>
    </>
  )
}

export default QuizBikeSelect
