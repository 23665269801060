import QuizBikeSelect from "../views/QuizBikeSelect"
import { graphql } from "gatsby"

export const query = graphql`
  query QuizBikeSelect {
    globalConfig: contentfulGlobalConfiguration {
      bodyFitShowInStockResults
    }
  }
`

export default QuizBikeSelect
